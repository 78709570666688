import React from 'react';
import AllProductCategory from '../AllProductCategory';
const WatchCategory = () => {
    return (
        <div className='py-12 '>
            <div >
                <AllProductCategory Category="saree" ></AllProductCategory>
            </div>
        </div>
    );
};

export default WatchCategory;